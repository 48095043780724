export const tokenomics = {
    totalSupply: "420690000000",
    publicPercent: "22",
    presalePercent: "40",
    teamPercent: "5",
    delegatesPercent: "5",
    marketingPercent: "8",
    developmentPercent: "7.5",
    vaultPercent: "2",
    airdropPercent: "7.5",
    stakingPercent: "5"
}